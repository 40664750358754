import { isElementInViewport } from '../../assets/scripts/util/functional'

const Component = {
  init() {
    this.lineAnimation()
  },
  lineAnimation() {
    const $lines = $('.js-line-animation')
    if ($lines.length) {
      $(window).on('load resize scroll', () => {
        $lines.each(function () {
          const $this = $(this)
          if (isElementInViewport(this)) {
            if (!$this.hasClass('is-animated')) {
              $this.addClass('is-animated')
              $this.animate(
                {
                  width: $this.find('svg').width(),
                },
                4000
              )
            }
          }
        })
      })
    }
  },
}

$(() => {
  Component.init()
})
